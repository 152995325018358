import React, {Component} from 'react';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ApiService from './ApiService';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = theme => ({
	searchbar: {
		margin: theme.spacing(2),
	  },
	 root: {
		height: 500,
    	width:  200,
	},
	button: {
	    margin: theme.spacing(1),
	  },  
	formControl: { 
	    marginLeft: 10,
	    minWidth: 300,
	    maxWidth: 300,
  },

});
	
class TransactionManagementComponent extends Component {
	constructor(props) {
        super(props)
        this.state = {
            merchantId: null,
			selectedFile: null,
			failed: false,
			responsemessage: null,
			success: false,
			configs: [],
			selectedConfig: null,
		}
		this.handleChange = this.handleChange.bind(this);
		this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.fetchStatusUploadConfigs = this.fetchStatusUploadConfigs.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

	fetchStatusUploadConfigs() {
		ApiService.fetchAllStatusUploadConfigs().then((res) => {
			this.setState({configs: res.data});
		})
	}

	onFileChangeHandler = (e) => {
        e.preventDefault();
        this.setState({
			failed: false,
			success: false,
            selectedFile: e.target.files[0]
        });
       
    };
	componentDidMount() {
		this.fetchStatusUploadConfigs();
	}

	uploadFile() {
		this.setState({success: false,failed: false});
		const formData = new FormData();
        formData.append('files', this.state.selectedFile);
		if(this.state.selectedConfig != null && this.state.selectedConfig!='')
			formData.append('configStr', JSON.stringify(this.state.selectedConfig));
        ApiService.uploadTxnStatus(formData)
            .then(res => {
					if(res.data.status == 'OK') {
						this.setState({success: true, responsemessage: res.data.responseMessage,selectedFile: null,})
					} else {
						this.setState({failed: true, responsemessage: res.data.responseMessage})						
					} 
            })
	}

	render(){
		const { classes } = this.props;
		return (
			
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em', minHeight: 400,}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Transactions status management 
					   </Typography>
					<Typography variant="subtitle1" color="secondary">
					(Only Excel Files are allowed, If using CSV open and save as XLSX )	
					</Typography>
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0"> Transactions status uploaded successfully ! , {this.state.responsemessage} </Alert> }
					<Grid container>
					<Grid item xs={12}>
					<FormControl className={classes.formControl}>
							  <InputLabel id="configSelectLabel">Select if uploading acquirer file: </InputLabel>
							  <Select value={this.state.selectedConfig}
							  		onChange={this.handleChange} name="selectedConfig"
							  		labelId="configSelectLabel" id="configSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.configs && this.state.configs.map((c) =>
								  	 <MenuItem key={c.id} value={c} dense>{c.acquirer.name + "-" + c.configType}</MenuItem>
								)}
							  </Select>
					</FormControl>
					</Grid>
					</Grid>
					&nbsp;
					<Box >                			
						<label htmlFor="file" style={{width: "100%",height: 300,background: 'lightgrey',textAlign: 'center'}}>		
						  <input type="file" id="file" name="file" onChange={this.onFileChangeHandler}  style={{height: 300, width:"100%", textAlign: 'center'}}></input>
							 <Typography style={{position: 'absolute',marginTop: -200, marginLeft: 240}} >Click or drag file here </Typography> 
						</label>
					</Box>
					<Grid justify="center" alignItems="center" container xs={12}>
					<Grid item xs={2}>
					<Button  variant="contained"
								        color="primary"
								        size="small"
								        className={classes.button}
										onClick={this.uploadFile}>Upload</Button>
					</Grid>
					</Grid>
				</Paper>
        	</React.Fragment>
		);
	}
}
export default withStyles(useStyles)(TransactionManagementComponent);