import React, {Component} from 'react';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ApiService from './ApiService';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = theme => ({
	searchbar: {
		margin: theme.spacing(2),
	  },
	 root: {
		height: 500,
    	width:  200,
	},
	button: {
	    margin: theme.spacing(1),
	  },
 	formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
});
	
class CardWhitelistComponent extends Component {
	constructor(props) {
        super(props)
        this.state = {
            merchantId: null,
			selectedFile: null,
			failed: false,
			responsemessage: null,
			success: false,
			merchants: [],
			selectedMerchant: null,
		}
		this.handleChange = this.handleChange.bind(this);
		this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.fetchAllMerchants = this.fetchAllMerchants.bind(this);
		this.fetchAllMerchants();
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	fetchAllMerchants(){
		ApiService.fetchAllMerchants()
			.then((res) => {
				this.setState({merchants: res.data})
			});		
	}
	onFileChangeHandler = (e) => {
        e.preventDefault();
        this.setState({
			failed: false,
			success: false,
            selectedFile: e.target.files[0]
        });
       
    };

	uploadFile() {
		this.setState({success: false,failed: false});
		const formData = new FormData();
        formData.append('files', this.state.selectedFile);
 		formData.append('cardMerchant', this.state.selectedMerchant);
        ApiService.uploadCardWhitelist(formData)
            .then(res => {
					if(res.data.status == 'OK') {
						this.setState({success: true, responsemessage: res.data.responseMessage,selectedFile: null,})
					} else {
						this.setState({failed: true, responsemessage: res.data.responseMessage})						
					} 
            }).catch(e => {
				this.setState({failed: true, responsemessage: e})
			});
	}
	MenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
	      width: 380,
	    },
	  },
	};

	render(){
		const { classes } = this.props;
		return (
			
			<React.Fragment>
				
				<Paper style={{margin: '1em', padding: '1em', minHeight: 400,}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Whitelist Cards 
					   </Typography>
					
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0"> {this.state.responsemessage} </Alert> }
					&nbsp;
					<Grid container  spacing={1} style={{paddingBottom: 50}}>
						<Grid>
					 <FormControl className={classes.formControl}>
							  <InputLabel id="merchantSelectLabel">Select Merchant</InputLabel>
							  <Select value={this.state.selectedMerchant}  style={{width: 350}}
							  		onChange={this.handleChange} name="selectedMerchant"
							  		labelId="merchantSelectLabel" id="merchantTerminalSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.merchants && this.state.merchants.map((m) =>
								  	 <MenuItem key={m.id} value={m.id} dense>{m.merchantName + "-" + m.code}</MenuItem>
								)}
							  </Select>
				   </FormControl>
					</Grid>
					</Grid>
					<Box >
                			
								<label htmlFor="file" style={{width: "100%",height: 300,background: 'lightgrey',textAlign: 'center'}}>		
								  <input type="file" id="file" name="file" onChange={this.onFileChangeHandler}  style={{height: 300, width:"100%", textAlign: 'center'}}></input>
									 <Typography style={{position: 'absolute',marginTop: -200, marginLeft: 240}} >Click or drag file here </Typography> 
								</label>
								
							
					</Box>
					<Grid justify="center" alignItems="center" container xs={12}>
					<Grid item xs={2}>
					<Button  variant="contained"
								        color="primary"
								        size="small"
								        className={classes.button}
										onClick={this.uploadFile}>Upload</Button>
					</Grid>
					</Grid>
				</Paper>
        	</React.Fragment>
		);
	}
}
export default withStyles(useStyles)(CardWhitelistComponent);