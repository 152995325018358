import React, { Component } from 'react';

import { Link, BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import LoginComponent from './LoginComponent';
import LogoutComponent from './LogoutComponent';
import AuthenticatedRoute from './AuthenticatedRoute';
import MerchantManagement from './MerchantManagementComponent';
import AcquirerManagement from './AcquirerManagementComponent';
import UserManagement from './UserManagementComponent';
import WireManagement from './WireManagementComponent';
import TxnManagement from './TransactionManagementComponent';
import MerchantTransactions from './MerchantTransactionsComponent';
import MerchantTransactionSummary from './MerchantTransactionSummaryComponent';
import CardWhitelist from './CardWhitelistComponent';
import Dashboard from './DashboardComponent';
import clsx from 'clsx';
import ApiService from './ApiService';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from './listItems';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SessionTimeout from './SessionTimeout';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright ©'}
			<Link color="inherit" to="https://transactbox.com/">
				transactbox.com
            </Link>{' '}
			{2020}{'.'}
		</Typography>
	);
}

const drawerWidth = 240;

const useStyles = theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		backgroundColor: theme.palette.primary.main,
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	medium: {
		width: '80px',
		height: '55px',
	},

	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
	merchantName: {
		marginRight: 36,
	},
	pci: {
	height: 60,
	width: 60,
 },
});


class InstructorApp extends Component {

	constructor(props) {
		super(props)
		this.state = {
			open: true,
			merchantName: null
		}
		this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
		this.handleDrawerClose = this.handleDrawerClose.bind(this)
		this.handleSuccessLogin = this.handleSuccessLogin.bind(this)
	}
	handleDrawerOpen = () => {
		if (ApiService.isUserLoggedIn()) {
			this.setState({ open: true })
		}
	};
	handleDrawerClose = () => {
		this.setState({ open: false })
	};

	onLogout = (event) => {
		this.handleDrawerClose();
		ApiService.logout();
		window.location.href = "/";
	}

	handleSuccessLogin() {
		var username= ApiService.fetchLoggedInMerchant();
		this.setState({merchantName: username}) 
	 	this.handleDrawerOpen();
	}

	componentDidMount() {
		if (this.isUserLoggedIn) {
			this.setState({ open: true })
		} else {
			this.setState({ open: false })
		}
	}

	render() {
		const isUserLoggedIn = ApiService.isUserLoggedIn();
		const { classes } = this.props;
		const LogoutButton = (props) => {
			return (
				<Button
					color="secondary"
					fullWidth
					size="large"
					onClick={props.onLogout}
					variant="contained"
				>
					Logout
				</Button>
			);
		}

		return (
			<Router>
				<div className={classes.root}>
					<CssBaseline />
					<SessionTimeout isAuthenticated={ApiService.isUserLoggedIn()} logOut={this.onLogout} />
					<AppBar position="absolute" className={clsx(classes.appBar, this.state.open && classes.appBarShift)} >
						<Toolbar className={classes.toolbar}>
							{!this.state.open && <>
							<IconButton edge="start" color="inherit" aria-label="open drawer" onClick={this.handleDrawerOpen}
								className={clsx(classes.menuButton, this.state.open && classes.menuButtonHidden)}>
								<MenuIcon />
							</IconButton>
							<Avatar alt="OAcquiring" variant="square" src="tnxtbox_header.png" className={classes.medium} ></Avatar>
							<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
								TransactBox
						</Typography></>
						}
						<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
								&nbsp;
						</Typography>
							<Typography component="h3" variant="subtitle1" color="inherit" noWrap className={classes.merchantName}>
								{this.state.merchantName && 'User : ' + this.state.merchantName}
							</Typography>
							<IconButton color="inherit" to="/login" onClick={this.onLogout}>
								<PowerSettingsNewIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Drawer variant="permanent"
						classes={{
							paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
						}}
						open={this.state.open}>

						<div className={classes.toolbarIcon} >
							<Avatar alt="OAcquiring" variant="square" src="tnxtbox_header.png" className={classes.medium} ></Avatar>
							<Typography variant="subtitle1" style={{color: 'white'}} noWrap>
								TransactBox
						    </Typography>
							<IconButton onClick={this.handleDrawerClose} style={{color: 'white'}}>
								<ChevronLeftIcon />
							</IconButton>
						</div>
						<Divider />
						<List>{mainListItems}</List>
					</Drawer>
					<main className={classes.content}>
						<div className={classes.appBarSpacer} />
						<Switch>
							<Route path="/" exact render={(props) => <LoginComponent {...props} handler={this.handleSuccessLogin} />} />
							<AuthenticatedRoute path="/logout" exact exact component={LogoutComponent} />
							<AuthenticatedRoute path="/users" exact component={UserManagement} />
							<AuthenticatedRoute path="/acquirerManagement" exact component={AcquirerManagement} />
							<AuthenticatedRoute path="/merchantManagement" exact component={MerchantManagement} />
							<AuthenticatedRoute path="/wireManagement" exact component={WireManagement} />
							<AuthenticatedRoute path="/transactions" exact component={MerchantTransactions} />
							<AuthenticatedRoute path="/transactionsSummary" exact component={MerchantTransactionSummary} />
							<AuthenticatedRoute path="/txnManagement" exact component={TxnManagement} />
							<AuthenticatedRoute path="/cardWhitelist" exact component={CardWhitelist} />
							<AuthenticatedRoute path="/dashboard" exact component={Dashboard} />
						</Switch>
						 { !ApiService.isUserLoggedIn() &&
							 <Box mt={2} align="center">
							 <Avatar alt="PCI" variant="square" src="pci.png" className={classes.pci}></Avatar>
							</Box>
						 }
						<Box mt={2}>
							<Copyright />
						</Box>
						
					</main>

				</div>

			</Router>

		)
	}
}

export default withStyles(useStyles)(InstructorApp)
