import React, { Component } from 'react';
import { Container, Card, CardContent, CardHeader, Divider, Grid, InputLabel, Select, Typography, MenuItem, Button, Box, FormControl, Input, FormControlLabel, Checkbox,TextField, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ApiService from './ApiService';
import Alert from '@material-ui/lab/Alert';

const useStyles = theme => ({
	topCard: {
		marginTop: theme.spacing(1),
		minHeight: '100vh',
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginBottom: 10,
	},

});

class AddAcquirerAccountForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: null,
			userName: null,
			password: this.generatePassword(),
			roleId: null,
			role: [],
			roles: [],
			merchants: [],
			userMerchantId: null,
			email: null,
			firstName: null,
			lastName: null,
			phone: null,
			city: null,
			state: null,
			country: null,
			zip: null,
			active: false,
			responseMessage: null,
			failed: false,
			success: false,
			portalPassword: null,
			signkey: Math.random().toString(16).slice(-8),
			industry: null,
			merchantName: null,
			orgRegistrationNumber: null,
			website: null,
			isIpWhiteList: true,
			isCardWhiteList: true,
			isMasterMerchant: false,
			isSubMerchant: false,
			isAdmin: false,
			merchantCity: null,
			merchantCountry: null,
			merchantZip: null,
			merchantAddress: null,
			merchantPhone: null,
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleRoleChange = this.handleRoleChange.bind(this);
		this.fetchAllMerchants = this.fetchAllMerchants.bind(this);
		this.fetchUserRoles = this.fetchUserRoles.bind(this);
	}
	generatePassword = (
		length = 20,
		wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$"
	) => Array(length)
		.fill('')
		.map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
		.join('');

	handleInputChange(event) {
		const target = event.target;
		const inputName = target.name;
		const inputValue = target.value;
		this.setState({
			[inputName]: inputValue
		});
	}
	handleRoleChange(event) {
		this.handleInputChange(event);
		this.setState({ roleId: event.target.value.id })
		if (event.target.value.type == 'ADMIN') {
			this.setState({ isMasterMerchant: false, isSubMerchant: false, isAdmin: true });
		} else if (event.target.value.type == 'SUBMERCHANT') {
			this.setState({ isMasterMerchant: false, isSubMerchant: true, isAdmin: false });
		} else if (event.target.value.type == 'MERCHANT') {
			this.setState({ isMasterMerchant: true, isSubMerchant: false, isAdmin: false });
		}

	}
	componentDidMount() {
		this.fetchAllMerchants();
		this.fetchUserRoles();
	}
	fetchUserRoles() {
		ApiService.fetchUserRoles().then((res) => {
			this.setState({ roles: res.data })
		});
	}

	fetchAllMerchants() {
		ApiService.fetchAllMerchants()
			.then((res) => {
				this.setState({ merchants: res.data })
			});
	}
	handleCheckboxChange(event) {
		if (event.target.checked)
			this.setState({ active: true });
		else
			this.setState({ active: false });
	}
	handleSubmit() {

		var request = Object.assign({}, this.state);

		ApiService.addAmendUser(request).then((res) => {
			if (res.data.status == 'OK') {
				this.setState({ failed: false, success: true, responseMessage: res.data.responseMessage });
				this.props.reloadData();
			}
			else
				this.setState({ failed: true, success: false, responseMessage: res.data.responseMessage })
		}).catch(e => {
			this.setState({ failed: true, responseMessage: "Error" })
		})

	}
	render() {
		const { classes } = this.props;
		return (

			<form >
				{this.state.failed && <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert>}
				{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
				
				<TextField name="userName" placeholder="Username" fullWidth  margin="dense" error={this.state.userName==null}
						value={this.state.userName} onChange={this.handleInputChange} required helperText={this.state.userName==null 
						&& "Value is required" }/>
				 
				<TextField name="password" placeholder="Password" error={this.state.password == null}  fullWidth  margin="dense" 
					value={this.state.password} onChange={this.handleInputChange} required helperText={this.state.password==null  && "Value is required" } />
				 
				<FormControl fullWidth margin="dense">
					<InputLabel id="roleIdLabel">User Role</InputLabel>
					<Select value={this.state.role} error={this.state.roleId==null}
						onChange={this.handleRoleChange} name="role" 
						labelId="roleIdLabel" id="role" aria-describedby="role-helper-text" >
						<MenuItem key="" value="">&nbsp; </MenuItem>
						{this.state.roles && this.state.roles.map((r) =>
							<MenuItem key={r.id} value={r} dense> {r.type} </MenuItem>
						)}
					</Select>
					{this.state.roleId==null &&	<FormHelperText id="role-helper-text" error>Value is required</FormHelperText>}
				</FormControl>
				 
				<TextField name="email" placeholder="Email" fullWidth error={this.state.email==null}  margin="dense" type="email"
					value={this.state.email} onChange={this.handleInputChange} required helperText={this.state.email==null 
						&& "Value is required" }/>
			 
				<FormControl fullWidth margin="dense">
					<Input name="phone" placeholder="Phone"
						value={this.state.phone} onChange={this.handleInputChange} />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<Input name="firstName" placeholder="First Name"
						value={this.state.firstName} onChange={this.handleInputChange} required />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<Input name="lastName" placeholder="Last Name"
						value={this.state.lastName} onChange={this.handleInputChange} required />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<Input name="address" placeholder="Address"
						value={this.state.address} onChange={this.handleInputChange} />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<Input name="city" placeholder="City"
						value={this.state.city} onChange={this.handleInputChange} required />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<Input name="country" placeholder="Country"
						value={this.state.country} onChange={this.handleInputChange} />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<Input name="zip" placeholder="Zip"
						value={this.state.zip} onChange={this.handleInputChange} />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.active}
								onChange={this.handleCheckboxChange}
								name="active"
								id="active"
								color="primary"
							/>
						}
						label="Is Active"
					/>
				</FormControl>
				{this.state.isSubMerchant &&
					<FormControl fullWidth margin="dense">
						<InputLabel id="merchantsLable">Select Merchant</InputLabel>
						<Select value={this.state.userMerchantId}
							onChange={this.handleInputChange} name="userMerchantId"
							labelId="merchantsLable" id="userMerchantId"
						>
							<MenuItem key="" value="">&nbsp; </MenuItem>
							{this.state.merchants && this.state.merchants.map((m) =>
								<MenuItem key={m.id} value={m.id} dense> {m.merchantName + "-" + m.code} </MenuItem>
							)}
						</Select>
					</FormControl>
				}
				{this.state.isMasterMerchant &&
					<>
						<Divider />
						 <Typography varient="h5" style={{ padding: '1em' }}> Merchant details </Typography>
						<Divider />
						 
						<TextField name="merchantName" placeholder="Merchant name" 
							value={this.state.merchantName} onChange={this.handleInputChange} required 
							fullWidth margin="dense"
							error={this.state.merchantName==null} helperText={this.state.merchantName == null && "Value is required"}/>
	 
					 
							<TextField name="signKey" placeholder="API SignKey"
								value={this.state.signKey} onChange={this.handleInputChange} 
								fullWidth margin="dense"
								error={this.state.signKey==null} helperText={this.state.signKey == null && "Value is required"}/>
				 
				 
							<TextField name="website" placeholder="Website"
								value={this.state.website} onChange={this.handleInputChange} 
								fullWidth margin="dense"
								error={this.state.website==null} helperText={this.state.website == null && "Value is required"}/>
						 
						<FormControl fullWidth margin="dense">
							<Input name="merchantPhone" placeholder="Merchant Phone"
								value={this.state.merchantPhone} onChange={this.handleInputChange} />
						</FormControl>
						<FormControl fullWidth margin="dense">
							<Input name="orgRegistrationNumber" placeholder="Org Registration Number"
								value={this.state.orgRegistrationNumber} onChange={this.handleInputChange} />
						</FormControl>
						<FormControl fullWidth margin="dense">
							<Input name="merchantAddress" placeholder="Merchant Address"
								value={this.state.merchantAddress} onChange={this.handleInputChange} />
						</FormControl>
						<FormControl fullWidth margin="dense">
							<Input name="merchantCity" placeholder="Merchant City"
								value={this.state.merchantCity} onChange={this.handleInputChange} required />
						</FormControl>
						<FormControl fullWidth margin="dense">
							<Input name="merchantCountry" placeholder="Merchant Country"
								value={this.state.merchantCountry} onChange={this.handleInputChange} required/>
						</FormControl>
						<FormControl fullWidth margin="dense">
							<Input name="merchantZip" placeholder="Merchant Zip"
								value={this.state.merchantZip} onChange={this.handleInputChange} />
						</FormControl>
						<FormControl fullWidth margin="dense">
							<Input name="industry" placeholder="Industry"
								value={this.state.industry} onChange={this.handleInputChange} />
						</FormControl>
						<FormControl fullWidth margin="dense">
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.isIpWhiteList}
										onChange={this.handleCheckboxChange}
										name="isIpWhiteList"
										id="isIpWhiteList"
										color="primary"
									/>
								}
								label="IP WhiteList Enabled"
							/>
						</FormControl>
						<FormControl fullWidth margin="dense">
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.isCardWhiteList}
										onChange={this.handleCheckboxChange}
										name="isCardWhiteList"
										id="isCardWhiteList"
										color="primary"
									/>
								}
								label="Card WhiteList Enabled"
							/>
						</FormControl>
					</>
				}
				<Box align="center">
					<Button onClick={this.handleSubmit} variant="contained" color="primary" >Save </Button>
				</Box>
			</form>

		);
	}
}
export default withStyles(useStyles)(AddAcquirerAccountForm)