import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ApiService from './ApiService';
import Spinner from './Spinner'
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MuiPickersUtilsProvider,DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
 searchbar: {
	margin: theme.spacing(2),
  },
 form: {

  width: 60,
},
formWrapper: {
  margin: theme.spacing(4),
  width: 200,
},
formError: {
  width: 200,
},
});

class EntityChargeConfigForm extends Component {
    constructor(props) {
        super(props)
		 this.state = {
		    txnSuccess: false,
			responsemessage: null,
			hasTxnFailed: false,
		}
	}
	generatePassword = (
	  length = 20,
	  wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
	) => Array(length)
	      .fill('')
	      .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
	      .join('');
	
	
	_handleSubmit = ({
    name,
    code,
    username,
	password,
	signkey,
	contactPerson,
	email,
	address,
	city,
	state,
	zip,
	country,
	telephone,
	fax,
	registrationNumber,
	industry,
	website,
	isIpWhitelist,
	isCardWhitelist,
	portalAdmin,
    setSubmitting,
    resetForm,
  }) => {
	const formData = {
		'merchantName': name,
		'code': code,
	    'username': username,
		'password': password,
		'signkey': signkey,
		'contactPerson': contactPerson,
		'contactEmail': email,
		'address': address,
		'city': city,
		'state': state,
		'zip': zip,
		'country': country,
		'telephone': telephone,
		'fax': fax,
		'orgRegistrationNumber': registrationNumber,
		'industry': industry,
		'website':website,
		'isIpWhiteList': isIpWhitelist==true?'Y':'N',
		'isCardWhiteList': isCardWhitelist==true?'Y':'N',
		'portalAdmin': portalAdmin==true?'Y':'N',
		
	}
	ApiService.addMerchant(formData)
		.then((res) => {
				if(res.data.status == 'OK') {
					 setSubmitting(false)
					 this.setState(() => ({
				        txnSuccess: true,
						hasTxnFailed: false,
						responsemessage: res.data.responseMessage,
				      }))
					 this.props.reloadMerchants();
				} else {
					this.setState(() => ({
				        txnSuccess: false,
						hasTxnFailed: true,
						responsemessage: res.data.responseMessage,
				      }))
					setSubmitting(false)
				}
            
		}).catch((e) => {
			this.setState(() => ({
				        txnSuccess: false,
						hasTxnFailed: true,
						responsemessage: 'Error',
				      }))
			setSubmitting(false)
			resetForm()
	})
  	}
	render() {
		const { classes } = this.props;
	    return (
		<>
		 	{this.state.hasTxnFailed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage} </Alert> }
			{this.state.txnSuccess &&  <Alert severity="success" elevation="0"> Merchant added successfully ! </Alert> }
			<Grid container xs={12} spacing={1} alignItems="center" justify="space-evenly">
				<Grid  item xs={8}>
					<Formik
				        initialValues={{
						    name:"",
						    code:"",
						    username:"",
							password: this.generatePassword(),
							signkey: Math.random().toString(16).slice(-8),
							contactPerson:"",
							email:"",
							address:"",
							city:"",
							state:"",
							zip:"",
							country:"",
							telephone:"",
							fax:"",
							registrationNumber:"",
							industry:"",
							website:"",
							isIpWhitelist:true,
							isCardWhitelist:true,
							portalAdmin:true,
				        }}
				        validationSchema={object().shape({
						  name: string().required('Name is requirred'),
				          username: string().required('Username(for dashboard) is required'),
				          email: string().required('Email is required'),
						  signkey: string().required('signkey is required'),
				          password: string().required('Password is required').matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    	),
						  address: string().required('Address is required'),
						  country: string().required('Country is required'),
						  website: string().required('Website is required'),
				        })}
				        onSubmit={(
				          { name,code,username,password,signkey,contactPerson,email,address,city,state,zip,country,telephone,fax,registrationNumber,industry,website,isIpWhitelist,isCardWhitelist,portalAdmin,},
				          { setSubmitting, resetForm }
				        ) =>
				          this._handleSubmit({
				            name,
						    code,
						    username,
							password,
							signkey,
							contactPerson,
							email,
							address,
							city,
							state,
							zip,
							country,
							telephone,
							fax,
							registrationNumber,
							industry,
							website,
							isIpWhitelist,
							isCardWhitelist,
							portalAdmin,
				            setSubmitting,
				            resetForm,
				          })
				        }

						render={props => {
					          const {
					            values,
					            touched,
					            errors,
					            handleChange,
					            handleBlur,
					            handleSubmit,
					            isValid,
					            isSubmitting,
					          } = props
					          return isSubmitting ? (
					            <Spinner />
					          ) : (
									<form onSubmit={handleSubmit} >
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="merchantName"
							                    name="name"
												placeholder="Merchant name"
							                    value={values.name}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.name && errors.name)}
											/>
										</FormControl>
										<FormControl margin="dense" fullWidth size="small">	
											<Input 
							                    id="merchanCode"
							                    name="code"
												placeholder="Merchant code"
							                    value={values.code}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.code && errors.code)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="username"
							                    name="username"
												placeholder="Merchant username"
							                    value={values.username}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.username && errors.username)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="password"
							                    name="password"
												placeholder="Merchant password"
							                    value={values.password}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.password && errors.password)}
											/>
											</FormControl>
											<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="signkey"
							                    name="signkey"
												placeholder="Merchant Signkey"
							                    value={values.signkey}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
												label="signkey"
							                    error={Boolean(touched.signkey && errors.signkey)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="contactPerson"
							                    name="contactPerson"
												placeholder="Contact person"
							                    value={values.contactPerson}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.contactPerson && errors.contactPerson)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="email"
							                    name="email"
												placeholder="Merchant email"
							                    value={values.email}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.email && errors.email)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="address"
							                    name="address"
												placeholder="Merchant address"
							                    value={values.address}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.address && errors.address)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="city"
							                    name="city"
												placeholder="Merchant city"
							                    value={values.city}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.city && errors.city)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="state"
							                    name="state"
												placeholder="Merchant state"
							                    value={values.state}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.state && errors.state)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="zip"
							                    name="zip"
												placeholder="Merchant zip"
							                    value={values.zip}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.zip && errors.zip)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="country"
							                    name="country"
												placeholder="Merchant country"
							                    value={values.country}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.country && errors.country)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="telephone"
							                    name="telephone"
												placeholder="Merchant telephone"
							                    value={values.telephone}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.telephone && errors.telephone)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="fax"
							                    name="fax"
												placeholder="Merchant fax"
							                    value={values.fax}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.fax && errors.fax)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="registrationNumber"
							                    name="registrationNumber"
												placeholder="Merchant registrationNumber"
							                    value={values.registrationNumber}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.registrationNumber && errors.registrationNumber)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="website"
							                    name="website"
												placeholder="Merchant website"
							                    value={values.website}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.website && errors.website)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<Input 
							                    id="industry"
							                    name="industry"
												placeholder="Merchant industry"
							                    value={values.industry}
							                    onChange={handleChange}
							                    onBlur={handleBlur}
							                    error={Boolean(touched.industry && errors.industry)}
											/>
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
								 			<FormControlLabel control={
												<Checkbox checked={values.isIpWhitelist} name="isIpWhitelist" id="isIpWhitelist" onChange={handleChange} onBlur={handleBlur} />
												} label ="IP Whitelist" />
											</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<FormControlLabel control={
												<Checkbox checked={values.isCardWhitelist} name="isCardWhitelist" id="isCardWhitelist" onChange={handleChange} onBlur={handleBlur} />
												} label ="Card Whitelist" />
												</FormControl>
										<FormControl margin="dense" fullWidth size="small">
											<FormControlLabel control={
												<Checkbox checked={values.portalAdmin} name="portalAdmin" id="portalAdmin" onChange={handleChange} onBlur={handleBlur} />
												} label ="Portal Admin" />
											 
											</FormControl>
											<Button
							                  type="submit"
							                  variant="contained"
							                  color="primary"
							                  disabled={Boolean(!isValid || isSubmitting)} fullWidth
											  style={{marginTop: '2em'}}
							                >
							                  {'Add'}
							                </Button> 
									</form>
									 
								)
					 }}
					/>
				</Grid>
			</Grid>
		</>
		);
	}
}
export default withStyles(useStyles)(EntityChargeConfigForm);