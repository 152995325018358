import React, { Component } from 'react'
import ApiService from './ApiService';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
	buttonProgress: {
    color: theme.palette.secondary.main,
	top: '50%',
    left: '50%',
    position: 'absolute',
	marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
});
class LoginComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            hasLoginFailed: false,
            showSuccessMessage: false,
			loading: false, 
        }
        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	
	handleKeyPress(ev) {
		if (ev.key === 'Enter') {
	      this.loginClicked()
	    }
	}
	
    loginClicked() {
		this.setState({ loading: true })
        ApiService
            .executeJwtAuthenticationService(this.state.username, this.state.password)
            .then(res => {
                ApiService.registerSuccessfulLoginForJwt(this.state.username, res.data.auth_token)
                this.props.history.push(`/txnManagement`)
				this.props.handler()
            }).catch(() => {
                this.setState({ showSuccessMessage: false })
                this.setState({ hasLoginFailed: true, loading:false })
				
            })

    }

    render() {
        const { classes, handler } = this.props;
        return (
           
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                   
                    <div className={classes.form}>
                    {this.state.hasLoginFailed &&  <Alert severity="error" elevation="0">Invalid Credentials !</Alert>}
                    <FormControl variant="outlined" className={classes.form}>
                        <InputLabel htmlFor="component-outlined1">User name</InputLabel>
                        <OutlinedInput id="component-outlined1" name="username" value={this.state.username} onChange={this.handleChange} label="username" />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.form}>
                        <InputLabel htmlFor="component-outlined2">Password</InputLabel>
                        <OutlinedInput id="component-outlined2" name="password" value={this.state.password} onKeyPress={this.handleKeyPress} onChange={this.handleChange} label="password" type="password"/>
                    </FormControl>
                     
{/*                         <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
						<div className={classes.wrapper}>
                        <Button
                            onClick={this.loginClicked}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
						{this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
               
            </Container>
        )
    }
}

export default withStyles(useStyles)(LoginComponent)
