import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ApiService from './ApiService';
import Spinner from './Spinner'
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MuiPickersUtilsProvider,DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
 searchbar: {
	margin: theme.spacing(2),
  },
 form: {

  width: 60,
},
formWrapper: {
  margin: theme.spacing(4),
  width: 200,
},
formError: {
  width: 200,
},
});

class EntityChargeConfigForm extends Component {
  constructor(props) {
        super(props)
		 this.state = {
		    txnSuccess: false,
			responsemessage: null,
			hasTxnFailed: false,
			chargeMasterId: null,
			masterCharges: this.props.masterCharges,
		}

  }
   copyDate = ({values}) => {alert(values.arbitrary)}


  _handleSubmit = ({
    chargeMasterId,
    chargeValue,
    chargeDescription,
	chargeCurrency,
	arbitrary,
	dateFrom,
	dateTo,
    setSubmitting,
    resetForm,
  }) => {

	ApiService.addWireCharge(dateFrom, dateTo, this.props.terminalId, this.props.accountId, chargeMasterId, chargeValue, chargeDescription, chargeCurrency, arbitrary)
		.then((res) => {
				if(res.data.status == 'OK') {
					 setSubmitting(false)
					 this.setState(() => ({
				        txnSuccess: true,
						hasTxnFailed: false,
				      }))
					resetForm()
					this.props.reloadData()
				} else {
					this.setState(() => ({
				        txnSuccess: false,
						hasTxnFailed: true,
						responsemessage: res.data.responseMessage
				      }))
					setSubmitting(false)
					resetForm()
				}
            
		}).catch((e) => {
			this.setState(() => ({
				        txnSuccess: false,
						hasTxnFailed: true,
						responsemessage: 'Error',
				      }))
			setSubmitting(false)
			resetForm()
	})
  }

  render() {
		const { classes } = this.props;
    return (
	<>
	  {this.state.hasTxnFailed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage} </Alert> }
	  {this.state.txnSuccess &&  <Alert severity="success" elevation="0"> Charge added successfully ! </Alert> }
	  <Grid container xs={12} spacing={1} alignItems="center" justify="space-evenly">
	  <Grid  item xs={8}>
      <Formik
        initialValues={{
		    chargeMasterId:'',
		    chargeValue: '',
		    chargeDescription: '',
			chargeCurrency: '',
			dateFrom: this.props.fromDate,
			dateTo: this.props.toDate,
			arbitrary: false,
        }}
        validationSchema={object().shape({
		  chargeMasterId: string().required('Must select charge'),
          chargeDescription: string().required('Charge description is required'),
          chargeValue: string().required('Charge value is required'),
          chargeCurrency: string().required('Currency is required'),
        })}
        onSubmit={(
          { chargeMasterId, chargeValue, chargeDescription,chargeCurrency, arbitrary, dateFrom, dateTo},
          { setSubmitting, resetForm }
        ) =>
          this._handleSubmit({
            chargeMasterId,
		    chargeValue,
		    chargeDescription,
			chargeCurrency,
			arbitrary,
			dateFrom,
			dateTo,
            setSubmitting,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return isSubmitting ? (
            <Spinner />
          ) : (
			 
				
              <form onSubmit={handleSubmit} style={{margin: '2em', padding: '2em'}}>
				 <FormControl  margin="dense" fullWidth size="small">
					  <InputLabel id="chargeMasterSelectLabel">Select charge</InputLabel>
					  <Select value={values.chargeMasterId} name="chargeMasterId"
					  		onChange={handleChange} 
					  		labelId="chargeMasterSelectLabel" id="chargeMasterSelect"
	 					>
						 {	this.state.masterCharges.map((ac) =>
						  	 <MenuItem key={ac.id} value={ac.id} dense>{ac.name + "-" + ac.type + "-" + ac.direction + "-" + ac.frequency}</MenuItem>
						)}
					  </Select>
				</FormControl>
				
				
                <FormControl margin="dense" fullWidth size="small">
                  <InputLabel
                    htmlFor="charge-description"
                    error={Boolean(touched.chargeDescription && errors.chargeDescription)}
                  >
                    {'Charge description'}
                  </InputLabel>
                  <Input 
                    id="charge-description"
                    name="chargeDescription"
                    value={values.chargeDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.chargeDescription && errors.chargeDescription)}
                  />
                  <FormHelperText
                    error={Boolean(touched.chargeDescription && errors.chargeDescription)}
                  >
                    {touched.chargeDescription && errors.chargeDescription
                      ? errors.chargeDescription
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.chargeValue && errors.chargeValue)}
                >
                  <InputLabel
                    htmlFor="charge-value"
                    error={Boolean(touched.chargeValue && errors.chargeValue)}
                  >
                    {'Charge value'}
                  </InputLabel>
                  <Input 
                    id="charge-value"
                    name="chargeValue"
                    value={values.chargeValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.chargeValue && errors.chargeValue)}
                  />
                  <FormHelperText
                    error={Boolean(touched.chargeValue && errors.chargeValue)}
                  >
                    {touched.chargeValue && errors.chargeValue ? errors.chargeValue : ''}
                  </FormHelperText>
	                </FormControl>
				<FormControl fullWidth margin="dense">					
   					<FormControlLabel control={
					<Checkbox checked={values.arbitrary} name="arbitrary" id="arbitrary-checkbox" onChange={handleChange} onBlur={handleBlur} />
					} label ="Permanent charge" />
				</FormControl>
				<FormControl fullWidth margin="dense">
					<MuiPickersUtilsProvider utils={DateFnsUtils} >
					<DatePicker autoOk 
								label="Valid From"
								format="MM/dd/yyyy"
								name="dateFrom"
								value={values.dateFrom} allowKeyboardControl="true" 
								onChange={handleChange} disabled={true}/>
					</MuiPickersUtilsProvider>
				</FormControl>
				<FormControl fullWidth margin="dense">
					<MuiPickersUtilsProvider utils={DateFnsUtils} >
					<DatePicker autoOk 
								label="Valid To"
								format="MM/dd/yyyy"
								name="dateTo"
								value={values.dateTo} 
								onChange={handleChange} disabled={true}/>
					</MuiPickersUtilsProvider>
				</FormControl>                
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.chargeCurrency && errors.chargeCurrency)}
                >
                  <InputLabel
                    htmlFor="charge-currency"
                    error={Boolean(touched.chargeCurrency && errors.chargeCurrency)}
                  >
                    {'Charge currency'}
                  </InputLabel>
                  <Input
                    id="charge-currency"
                    name="chargeCurrency"
                    value={values.chargeCurrency}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.chargeCurrency && errors.chargeCurrency)}
                  />
                  <FormHelperText
                    error={Boolean(touched.chargeCurrency && errors.chargeCurrency)}
                  >
                    {touched.chargeCurrency && errors.chargeCurrency
                      ? errors.chargeCurrency
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)} fullWidth
				  style={{marginTop: '2em'}}
                >
                  {'Add'}
                </Button> 
              </form>
            
          )
        }}
      />
	 </Grid>
	 
	 </Grid>
	</>
    )
  }
} 
export default withStyles(useStyles)(EntityChargeConfigForm);
