import React, {Component} from 'react';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ApiService from './ApiService';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable, { MTableToolbar } from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths, subDays, format} from 'date-fns';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import ReverseIcon from '@material-ui/icons/SettingsBackupRestore';

import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
  chipRoot: {
	display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
   crossError: {
    color: theme.palette.error.main,
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  menuText: {
  	fontWeight: theme.typography.fontWeightMedium,
  },
	backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class MerchantTransactionsComponent extends Component {
	
	constructor(props) {
        super(props)
        this.state = {
            transactions: [],
			result: [],
			merchantId: null,
            message: null,
			fromDate: subDays(new Date(),20),
			toDate: new Date(),
			order: 'desc',
			orderBy: 'Date',
			loading: false,
			statusCriteria:['ALL'],
			orderNoCriteria: null,
			email: null,
			merchantTerminals: null,
			terminalId: null,
			failed: false,
			success: false,
			successMessage: null,
			refundReason: '',
			refundPanelVisible: false,
			refundAmount: 0.00,
			refundTxnId: null,
			criteriaTxnId: null,
			txnUpdatePanelVisible: false,
			targetStatus: null,
			gatewayResponseTrackerId: null,
			transactionInfo: null,
			signInfo: null,
        }
       this.reloadTransactions = this.reloadTransactions.bind(this); 
	   this.handleChange = this.handleChange.bind(this); 
       this.handleStatusSelectChange = this.handleStatusSelectChange.bind(this); 
       this.handleOrderNoChange = this.handleOrderNoChange.bind(this);
       this.handleEmailChange = this.handleEmailChange.bind(this);
	   this.searchTransactions = this.searchTransactions.bind(this);
	   this.refundTransaction = this.refundTransaction.bind(this);
	   this.hideRefundPanel = this.hideRefundPanel.bind(this);
	   this.handleClose = this.handleClose.bind(this);
	   this.showTxnUpdatePanel = this.showTxnUpdatePanel.bind(this);
 	   this.hideTxnUpdatePanel = this.hideTxnUpdatePanel.bind(this);
	   this.handleTargetStatusSelectChange = this.handleTargetStatusSelectChange.bind(this);
	   this.changeTransactionStatus = this.changeTransactionStatus.bind(this);
    }
	componentDidMount() {
        {/* this.reloadTransactions();*/}
		this.fetchMerchantTerminals();
    }
	hideRefundPanel() {
		this.setState({txnUpdatePanelVisible: false});
	}
	hideTxnUpdatePanel() {
		this.setState({txnUpdatePanelVisible: false});
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

	reloadTransactions() { 
		this.setState({loading: true});
        ApiService.fetchMerchantTransactions(format(this.state.fromDate,'yyyyMMdd'), 
        	format(this.state.toDate,'yyyyMMdd'), this.state.statusCriteria, this.state.orderNoCriteria, this.state.email, this.state.terminalId, this.state.criteriaTxnId)
            .then((res) => {
                this.setState({result: res.data.transactions,merchantId: res.data.merchantId,loading: false, failed: false})
            }).catch((e) => {
				
			});
    }

	searchTransactions() {
		if(this.state.terminalId == null  && this.state.criteriaTxnId == null) {
			this.setState({responsemessage:"Please select Merchant Terminal or Transaction ID",failed: true});
			return;
		}
		this.reloadTransactions();
	}
 	handleStatusSelectChange(event) {
 		this.setState({statusCriteria: event.target.value})
 	}
	handleTargetStatusSelectChange(event) {
 		this.setState({targetStatus: event.target.value})
 	}
 	handleOrderNoChange(event) {
 		this.setState({orderNoCriteria: event.target.value})
 	}
 	handleEmailChange(event) {
 		this.setState({email: event.target.value})
 	}
	handleClose() {
		this.setState({loading: false});
	}
	
	fetchMerchantTerminals(){
		ApiService.fetchMerchantTerminals()
			.then((res)=> {
				this.setState({merchantTerminals: res.data})			
			}).catch((e)=> {
				
			});
	}
	showRefundPanel(txn) {
		this.setState({refundPanelVisible: true, refundAmount: txn.amount, refundTxnId: txn.merchantTransactionId})
	}
	showTxnUpdatePanel(txn) {
		this.setState({txnUpdatePanelVisible: true, refundTxnId: txn.merchantTransactionId})
	}
	refundTransaction() {
		this.setState({loading: true});
		ApiService.refundMerchantTransaction(this.state.refundTxnId, this.state.refundReason, this.state.refundAmount)
			.then((res)=> {
				if(res.data.status=='SUCCESS')
					this.setState({responsemessage: "Refund initiated successfully", success: true, failed: false})
				else
					this.setState({responsemessage: "Refund request failed", success: false, failed: true})

				this.reloadTransactions();			
			}).catch((e)=> {
				this.setState({responsemessage: "Error refunding transaction "+ e, success: false, failed: true})
			});
		this.setState({refundPanelVisible: false,refundAmount: 0.00, refundTxnId: null});	
	}

	changeTransactionStatus() {
		ApiService.changeTransactionStatus(this.state.refundTxnId, this.state.targetStatus, this.state.gatewayResponseTrackerId, this.state.transactionInfo, this.state.signInfo)
			.then(res => {
				if(res.data.status=='OK') {
					this.setState({responsemessage: res.data.responseMessage, success: true, failed: false, txnUpdatePanelVisible: false});
					this.reloadTransactions();
				}else
					this.setState({responsemessage: res.data.responseMessage, success: false, failed: true, txnUpdatePanelVisible: false});
			}).catch((e)=> {
				this.setState({responsemessage: "Error changing transaction status"+ e, success: false, failed: true, txnUpdatePanelVisible: false});
			});
	}
	
	headCells = [
	  { id: 'Terminal_Id', numeric: false, disablePadding: false, label: 'Terminal Id' },
	  { id: 'Date', numeric: false, disablePadding: false, label: 'Date' },
	  { id: 'Txn_Id', numeric: false, disablePadding: false, label: 'Txn Id' },
	  { id: 'Type', numeric: false, disablePadding: false, label: 'Type' },
	  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
	  { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },	
	  { id: 'Currency', numeric: false, disablePadding: false, label: 'Currency' },
	];
	headCells2 = [
	  { id: 'Date', numeric: false, disablePadding: false, label: 'Date' }, 
	  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
	  { id: 'Captured', numeric: false, disablePadding: false, label: 'Captured amnt' },
 	  { id: 'ChargBck', numeric: false, disablePadding: false, label: 'Chargeback amnt' },
	  { id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'Billing desc' }, 
	  { id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'TrackerId' }, 
	  { id: 'txnInfo', numeric: false, disablePadding: false, label: 'Txn Info' }, 
	  { id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'SignInfo' }, 
	];
	ITEM_HEIGHT = 50;
	ITEM_PADDING_TOP = 8;
 	MenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
	      width: 250,
	    },
	  },
	};
	TerminalMenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 5.5 + this.ITEM_PADDING_TOP,
	      width: 500,
	    },
	  },
	};
 	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	  };
	render(){
		const { classes } = this.props;
		return (
			<React.Fragment>
				
			  <Paper style={{margin: '1em', padding: '1em'}}>
			   <Typography component="h2" variant="h6" color="primary" gutterBottom>
			      Merchant Transactions
			   </Typography>
				{this.state.failed &&  <Alert severity="error" elevation={0}> {this.state.responsemessage}</Alert> }
				{this.state.success &&  <Alert severity="success" elevation={0}> {this.state.responsemessage}</Alert> }
				<Divider light className={classes.searchbar}/>
				  <Grid container xs={12} spacing={1}>				  
				  <MuiPickersUtilsProvider utils={DateFnsUtils} >
							  <DatePicker autoOk 
								label="From"
								format="MM/dd/yyyy"
								value={this.state.fromDate} 
								onChange={(val)=> this.setState({fromDate: val})} />
								&nbsp;	&nbsp;	&nbsp;
							 <DatePicker autoOk
						          label="To"
						          format="MM/dd/yyyy"
						          value={this.state.toDate}
						          onChange={(val) =>  this.setState({toDate: val})}
						        />
				  </MuiPickersUtilsProvider>
				  <FormControl className={classes.formControl}>
					  <InputLabel id="merchantTerminalSelectLabel">Terminal</InputLabel>
					  <Select value={this.state.merchantTerminalId}  MenuProps={this.TerminalMenuProps}
					  		onChange={this.handleChange} name="terminalId"
					  		labelId="merchantTerminalSelectLabel" id="merchantTerminalSelect"
	 					>
						<MenuItem key="" value="">&nbsp; </MenuItem>	
						 {	this.state.merchantTerminals && this.state.merchantTerminals.map((mt) =>
						  	 <MenuItem key={mt.terminalId} value={mt.terminalId} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
						)}
					  </Select>
				  </FormControl>
				  <FormControl className={classes.formControl}>
					  <InputLabel id="statusSelectLabel">Status</InputLabel>
					  <Select multiple 
					  		value={this.state.statusCriteria}  MenuProps={this.MenuProps}
					  		onChange={this.handleStatusSelectChange} 
					  		labelId="statusSelectLabel" id="statusSelect"
	 					>
					  	 <MenuItem key={"SALE_SUCCESSFUL"} value={"SALE_SUCCESSFUL"} dense>SALE_SUCCESSFUL</MenuItem>
					  	 <MenuItem key={"SALE_FAILED"} value={"SALE_FAILED"} dense>SALE_FAILED</MenuItem>
						 <MenuItem key={"SALE_PENDING"} value={"SALE_PENDING"} dense>SALE_PENDING</MenuItem>
					  	 <MenuItem key={"REFUND_SUCCESSFUL"} value={"REFUND_SUCCESSFUL"} dense>REFUND_SUCCESSFUL</MenuItem>
					  	 <MenuItem key={"REFUND_FAILED"} value={"REFUND_FAILED"} dense>REFUND_FAILED</MenuItem>
						 <MenuItem key={"CHARGEBACK"} value={"CHARGEBACK"} dense>CHARGEBACK</MenuItem>
						 <MenuItem key={"SETTLED"} value={"SETTLED"} dense>SETTLED</MenuItem>
					  	 <MenuItem key={"ALL"} value={"ALL"} dense>ALL</MenuItem>
					  </Select>
					  
				  </FormControl>
				  <TextField id="orderNo" label="Order Number" onChange={this.handleOrderNoChange} className={classes.formControl}/>
				  <TextField id="email" label="Customer email" onChange={this.handleEmailChange} className={classes.formControl}/>
				  <TextField id="criteriaTxnId" name="criteriaTxnId" label="Transaction Id" onChange={this.handleChange} className={classes.formControl}/>
				    
				</Grid>
				<br/>
				(Dates max 1 month apart)
			  	<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>
			  	  <Grid item xs={2}>
			  	    <Button
				        variant="contained"
				        color="primary"
				        size="small"
				        className={classes.button}
				        startIcon={<SearchIcon />}
						onClick={this.searchTransactions}
				      >
				        Search
				    </Button>
				    <Backdrop open={this.state.loading} className={classes.backdrop} onClick={this.handleClose}><CircularProgress color="inherit" /></Backdrop>
				    </Grid>
			  	</Grid>
		      <MaterialTable
			      title=""
				  icons={this.tableIcons}
			      columns={[
			        { field: 'terminalId', title: 'Terminal',render: rowData => <>
							<Tooltip title={rowData.terminalId} interactive>
								 <InfoOutlinedIcon/>
							</Tooltip>
						</>
					},
			        { field: 'createdDisplay', title: 'Date', type:'date', defaultSort: 'desc' , render: rowData => <Typography noWrap="true" variant="caption">{rowData.createdDisplay}</Typography> },
			        { field: 'merchantTransactionId' , title: 'Txn Id', render: rowData => <>
							<Tooltip title={rowData.merchantTransactionId} interactive>
								 <InfoOutlinedIcon/>
							</Tooltip>
						</>},
					{ field: 'merchantOrderNo' , title: 'Ord No', render: rowData => <>
							<Tooltip title={rowData.merchantOrderNo} interactive>
								 <InfoOutlinedIcon/>
							</Tooltip>
						</>},
					{ field: 'transactionType', title: 'Pay Method',render: rowData=> <Typography variant="caption">
								{ rowData.transactionType!= null && rowData.transactionType=="SALE" && "CC"}
								{ rowData.transactionType!= null && rowData.transactionType=="REFUND" && "CC"}
								{ rowData.transactionType!= null && rowData.transactionType=="CHECKOUT" && "Other"} 
							</Typography> 
					},
			        {field: 'cardType', title: 'Type',cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
			        {field: 'statuses[0].transactionStatus', title: 'Status', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
					{field: 'amount', title: 'Amount', type: 'numeric', render: rowData=><Typography variant="caption">{rowData.amount && rowData.amount.toFixed(2)}</Typography> },
					{field: 'currency', title: 'Currency', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
					{field: 'customerEmail', title: 'Cust Email', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
					{field: 'isSettled', title: 'Settled', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
					{field: 'refundAmount', title: 'Refund amt', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
					
			      ]}
			      data={this.state.result}  
				  localization={{
					toolbar: {
			            exportName: 'Export as XL'
			        },
				  }}
				 actions={[ 
					 rowData => ({
						tooltip: 'Refund transaction',
						icon: (props)=> <Button variant="contained" color="primary" size="small" startIcon={<ReverseIcon {...props}/>} disabled={!(rowData.statuses[0].transactionStatus == 'SALE_SUCCESSFUL' || rowData.statuses[0].transactionStatus =='REFUND_FAILED')} > Refund </Button>,
						onClick: (evt, rowData) => this.showRefundPanel(rowData),
						disabled: !(rowData.statuses[0].transactionStatus == 'SALE_SUCCESSFUL' || rowData.statuses[0].transactionStatus =='REFUND_FAILED'),
					}),
					rowData => ({
						tooltip: 'Change transaction Status',
						icon: (props)=> <Button variant="contained" color="primary" size="small" startIcon={<EditIcon {...props}/>} disabled={!(rowData.statuses[0].transactionStatus == 'SALE_STARTED' || rowData.statuses[0].transactionStatus =='SALE_FAILED' || rowData.statuses[0].transactionStatus =='SALE_PENDING') } > Status </Button>,
						onClick: (evt, rowData) => this.showTxnUpdatePanel(rowData),
						disabled: !(rowData.statuses[0].transactionStatus == 'SALE_STARTED' || rowData.statuses[0].transactionStatus =='SALE_FAILED' || rowData.statuses[0].transactionStatus =='SALE_PENDING'),
					})
				  ]}
				  options={{
					search:true,
			        exportButton: true,
					exportCsv: (columns, data) => {
						ApiService.fetchTransactionsXL(format(this.state.fromDate,'yyyyMMdd'), 
        	format(this.state.toDate,'yyyyMMdd'), this.state.statusCriteria, this.state.orderNoCriteria, this.state.email, this.state.terminalId);
					},
					pageSize: 20,
					pageSizeOptions: [20,50,100],
					padding: 'dense',
					selection: false,
					actionsColumnIndex: 12,
			      }}
				  detailPanel={[
			        {
			          tooltip: 'Show details',
			          render: rowData => {
			            return (
			               <Table size="small" >
							 <TableHead>
								<TableRow className={classes.detailhead}>
									{this.headCells2.map((head) => (
										<StyledTableCell
								            key={head.id}
								            align='left'  
								          > 
											{head.label}
						         		 </StyledTableCell>
									))}
								</TableRow>
							 </TableHead>
							<TableBody>
							{rowData.statuses.map((txn,index) => 
								<TableRow>
									<TableCell align="left">{format(new Date(txn.created),'dd MMM yyyy HH:mm:ss')}</TableCell>
									<TableCell align="left">{txn.transactionStatus}</TableCell>
									<TableCell align="left">{rowData.capturedAmount && rowData.capturedAmount}</TableCell>
									<TableCell align="left">{txn.transactionStatus=="CHARGEBACK" && rowData.chargeBackAmount && rowData.chargeBackAmount}</TableCell>
									<TableCell align="left">{txn.gatewayResponse[0] && txn.gatewayResponse[0].billingDescriptor}</TableCell>
									<TableCell align="left">{txn.gatewayResponse[0] && txn.gatewayResponse[0].gatewayResponseTrackerId}</TableCell>
									<TableCell align="left">{txn.gatewayResponse[0] && txn.gatewayResponse[0].txnInfo}</TableCell>
									<TableCell align="left">{txn.gatewayResponse[0] && txn.gatewayResponse[0].signInfo}</TableCell>
								</TableRow> 
							)}								
							</TableBody>							
						   </Table>
			            )
			          },
			        },
				  ]}     
			    />
				<Dialog open={this.state.refundPanelVisible} onClose={this.hideRefundPanel}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideRefundPanel}>
			          Refund Transaction
			        </DialogTitle>
					<DialogContent>
					 <Grid>
						<Grid xs={12}>
						 <FormControl>
							<Input name="refundReason"  placeholder="Refund reason" 
		                        value={this.state.refundReason} onChange={this.handleChange} />
						 </FormControl>
						</Grid>
						<Grid xs={12}>
						 <FormControl>
							<Input name="refundAmount"  placeholder="Refund amount" type="amount" 
		                        value={this.state.refundAmount} onChange={this.handleChange} />
						 </FormControl>
						</Grid>
						<Grid xs={12}>
						 <Box align="center" style={{marginTop: '1em'}}>  
							<Button onClick={this.refundTransaction} variant="contained" color="primary" >Refund </Button>
						</Box> 
						</Grid>
					</Grid>
					</DialogContent>
				</Dialog>
				<Dialog open={this.state.txnUpdatePanelVisible} onClose={this.hideTxnUpdatePanel}>
					<DialogTitle  onClose={this.hideTxnUpdatePanel}>
						Change Transaction Status
					</DialogTitle>
					<DialogContent>
					 <Grid>
						<Grid xs={12}>
						 
							<InputLabel id="targetSstatusLable">Target status</InputLabel>	
							 <Select  value={this.state.targetStatus} className={classes.formControl}
							  		onChange={this.handleTargetStatusSelectChange} 
							  		labelId="targetSstatusLable" id="statusSelect"
			 					>
							  	 <MenuItem key={"SALE_SUCCESSFUL"} value={"SALE_SUCCESSFUL"} dense>SALE_SUCCESSFUL</MenuItem>
							  	 <MenuItem key={"SALE_FAILED"} value={"SALE_FAILED"} dense>SALE_FAILED</MenuItem>
							  </Select>
						 
						</Grid>
						<Grid xs={12}>
						<TextField name="gatewayResponseTrackerId" label="Tracker Id" value={this.state.gatewayResponseTrackerId}
							onChange={this.handleChange} className={classes.formControl}/>
							</Grid>
						<Grid xs={12}>
						<TextField name="transactionInfo" label="Transaction Info" value={this.state.transactionInfo}
							onChange={this.handleChange} className={classes.formControl}/>
						 </Grid>
						<Grid xs={12}>
						 <TextField name="signInfo" label="Sign Info" value={this.state.signInfo}
							onChange={this.handleChange} className={classes.formControl}/>
						 </Grid>
						<Grid xs={12}>
							<Box align="center" style={{marginTop: '1em'}}>  
								<Button onClick={this.changeTransactionStatus} variant="contained" color="primary" >Change status </Button>
							</Box> 
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</Paper>
			</React.Fragment>
		);
	}
}	

export default withStyles(useStyles)(MerchantTransactionsComponent);

