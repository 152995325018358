import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/SyncProblem';
import { Link } from "react-router-dom";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MerchantIcon from '@material-ui/icons/SupervisedUserCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/dashboard">
      <ListItemIcon>&nbsp;
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
	<ListItem button component={Link} to="/users">
      <ListItemIcon>&nbsp;
        <PeopleAltIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
	<ListItem button component={Link} to="/acquirerManagement">
      <ListItemIcon>&nbsp;
      	<AccountBalanceIcon />
      </ListItemIcon>
      <ListItemText primary="Acquirer Manage" />
    </ListItem>
    <ListItem button component={Link} to="/merchantManagement">
      <ListItemIcon>&nbsp;
      	<MerchantIcon />
      </ListItemIcon>
      <ListItemText primary="Merchant Manage" />
    </ListItem>
    <ListItem button component={Link} to="/wireManagement">
      <ListItemIcon>&nbsp;
      	<ReceiptIcon />
      </ListItemIcon>
      <ListItemText primary="Wire" />
    </ListItem>
	<ListItem button component={Link} to="/transactions">
      <ListItemIcon>
        <Avatar src="combo_chart.svg" variant="square" style={{width: 30, height: 30,}}/>
      </ListItemIcon>
      <ListItemText primary="Transactions" />
    </ListItem>
	<ListItem button component={Link} to="/transactionsSummary">
      <ListItemIcon>&nbsp;
        <FormatListBulletedIcon/>
      </ListItemIcon>
      <ListItemText primary="Txn Summary" />
    </ListItem>
      <ListItem button component={Link} to="/txnManagement">
      <ListItemIcon>&nbsp;
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Transaction status" />
    </ListItem>
 	<ListItem button component={Link} to="/cardWhitelist">
      <ListItemIcon>&nbsp;
        <CreditCardIcon />
      </ListItemIcon>
      <ListItemText primary="Card Whitelist" />
    </ListItem>
      {/*<ListItem button component={Link} to="/merchants">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Merchants" />
    </ListItem> 
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Statements" />
    </ListItem>
   <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem>*/}
  </div>
);
